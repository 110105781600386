import { Circles } from "react-loader-spinner";

const CommonLoader = () => {
    return (
        <div className="loading-body relative-wrapper">
            <div className="vertical-align">
                <Circles
                    height="200"
                    width="200"
                    radius="9"
                    color="#251D5C"
                    ariaLabel="loading"
                    wrapperClass="loaderWrapper"
                />
            </div>
        </div>
    );
};

export default CommonLoader;
