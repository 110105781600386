// src/components/PrivateRoute.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, privateRoute, element, ...rest }) => {
  return (
    <Routes>
        <Route 
            element = {
                (!isAuthenticated) ? (<Navigate to="/" />)
                :
                element
            }
            {...rest} 
        />
    </Routes>
  );
};

// const PrivateRoute = ({ element: Element, isAuthenticated, ...rest }) => {
//   return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" />;
// };

export default PrivateRoute;