export let endpoints = (request = {}) => {
    let request_headers = null;
    let request_headers_json = null;
    let user_id = null;
    let client_params = null;

    if(request != null) {
        
        if(request.token) {
            request_headers_json = { 'Authorization' : request.token, 'Content-Type' : 'application/json' };
        } else if(request.bearer_token) {
            request_headers_json = {
                Authorization: "Bearer " + request.bearer_token,
                "Content-Type": "application/json",
            };
        } else {
            request.token = null;
        }
        
        if(request.user_id) {
            user_id = request.user_id;
        }

        if(request.params) {
            client_params = request.params;
        }

    } else {
        request = {};
    }


    return {
        /* ################################ AUTHENTICATION ENDPOINTS ################################ */
        
        authentication_login: {
            url: "/api/v1/authenticate-admin",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            isFileUpload: false,
        },

        /* ################################ AUTHENTICATION ENDPOINTS ################################ */

        
        minister_instance: {
            url: "/api/members/"+request.id,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        ministers_list: {
            url: "/api/members?page=" + request.page+"&search_string="+request.search_string+"&locale_ids="+request.locale_ids+"&groups="+request.groups,
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },

        ministers_search: {
            url: "/api/members/search",
            method: "POST",
            headers: request_headers_json,
            isFileUpload: false,
        },

        /* ################################ GLOBAL CONFIG ENDPOINTS ################################ */

        all_locale_list: {
            url: "/api/v1/config/locales/all",
            method: "GET",
            headers: request_headers_json,
            isFileUpload: false,
        },
    };
};
