import {Component} from 'react';

import React from "react";
import Slider from "react-slick";

import pmccIcon from "../assets/pmcc-icon.png";

import ArtF from "../assets/ArtF.webp";
import DodoyF from "../assets/DodoyF.webp";
import OsieQ from "../assets/OsieQ.webp";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const group_categories = [
    "Bishops",
    "Council Members",
    "Presbyters",
    "Area Coordinators",
    "Luzon Area Coordinators",
    "Zion",
    "Bethel",
    "Mizpah",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer"
];

const settings = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 1,
    infinite: false,        // Disable infinite scroll
    swipeToSlide: true,     // Allow scrolling to any position
    speed: 300,             // Adjust speed for smoother transitions
    variableWidth: true,
};

class Contact extends Component {
    render() {

        let category_toggle = [];
        group_categories.forEach((category) => {
            category_toggle.push(
                <div className="category-toggle-instance">
                    <div className="group-label">{category}</div>
                </div>
            );
        });

        return (
            <div>
                <div className="wrapper">
                    <div className="section-limiter">
                        <div className="sectionHeader">
                            <div className="row">
                                <div className="col-8"><h6 className="text-left">Contacts</h6></div>
                                <div className="col-4 text-right">
                                    <div id="add-contact">+</div>
                                </div>
                            </div>
                        </div>

                        <div id="searchWrapper" className="contacts-element">
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="Search" />
                            </div>
                        </div>

                        <div className="slider-container">
                          <Slider {...settings}>
                            {category_toggle}
                          </Slider>
                        </div>

                        <div className="sectionHeader">
                            <div className="row">
                                <div className="col-8"><h6 className="text-left">All Contacts</h6></div>
                                <div className="col-4"><a className="subHeaderLabel pull-right">See All</a></div>
                            </div>
                        </div>

                        <div className="contacts-element" id="contactWrapper">
                            <div className="contact-instance">
                                <div className="contact-infromation-wrapper">
                                    <div className="call-action-wrapper"><i className="fa fa-phone" /></div>
                                    <div style={{background: `url(${ArtF})`}} className="contact-image"></div>
                                    <div className="contact-information">
                                        <div className="contact-designation">Church Archbishop</div>
                                        <div className="contact-name">Archbishop Arturo Ferriol</div>
                                        <div className="contact-area">Bethel Area</div>
                                    </div>
                                </div>
                            </div>

                            <div className="contact-instance">
                                <div className="contact-infromation-wrapper">
                                    <div className="call-action-wrapper"><i className="fa fa-phone" /></div>
                                    <div style={{background: `url(${DodoyF})`}} className="contact-image"></div>
                                    <div className="contact-information">
                                        <div className="contact-designation">Church Archbishop</div>
                                        <div className="contact-name">Archbishop Arturo Ferriol</div>
                                        <div className="contact-area">Bethel Area</div>
                                    </div>
                                </div>
                            </div>

                            <div className="contact-instance">
                                <div className="contact-infromation-wrapper">
                                    <div className="call-action-wrapper"><i className="fa fa-phone" /></div>
                                    <div style={{background: `url(${OsieQ})`}} className="contact-image"></div>
                                    <div className="contact-information">
                                        <div className="contact-designation">Church Archbishop</div>
                                        <div className="contact-name">Archbishop Arturo Ferriol</div>
                                        <div className="contact-area">Bethel Area</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Contact;
