import Dashboard from "../Pages/dashboard";
import Contact from "../Pages/contacts";
import ContactProfile from "../Pages/contact-profile";

const routes = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        exact: true,
    },

    {
        path: "/contacts",
        element: <Contact />,
        exact: true,
    },

    {
        path: "/contact-profile/:id",
        element: <ContactProfile />,
        exact: true,
    }
];

export default routes;
