import { React, Component, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { api } from '../api.js';
import { config } from '../config.js';

import pmccIcon from "../assets/pmcc-icon.png";
import fillerPhoto from "../assets/filler.png";

import ArtF from "../assets/ArtF.webp";
const token = localStorage.getItem("auth_token");
const headerReq = { token };

const group_categories = [
    "Bishops",
    "Council Members",
    "Presbyters",
    "Area Coordinators",
    "Luzon Area Coordinators",
    "Zion",
    "Bethel",
    "Mizpah",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer"
];

const ContactProfile = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleGoBack = () => {
        navigate(-1); // This will go back to the previous page
    };

    // State variables to store data, loading state, and error
    const [loading, setLoading] = useState(true);
    const [minister, setMinister] = useState(null);
    const [error, setError] = useState(null);

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    // useEffect hook to perform side effect (API call)
    useEffect(() => {
        // Define an async function to fetch data
        const fetchData = async () => {
            try {
                
                headerReq.id = id;
                api("minister_instance", headerReq, null, "member")
                .then((result) => {

                    setMinister(result);

                }).catch((error) => {

                    console.log(error);
                    console.log("Something went wrong. " + JSON.stringify(error));

                });

            } catch (err) {
                // Handle any errors that occur during the API call
                setError(err);
            } finally {
                // Update loading state regardless of success or error
                setLoading(false);
            }
        };

        // Call the async function
        fetchData();
    }, []); // Empty dependency array means this effect runs once after mount


    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */


    // Render different UI based on the state
    if (loading) {
        return <p>Loading...</p>;
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    // const { id } = this.props.match.params;
    let category_toggle = [];
    group_categories.forEach((category, index) => {
        category_toggle.push(
            <div key={index} className="category-toggle-instance">
                <div className="group-label">{category}</div>
            </div>
        );
    });

    return (
        <div id="contact-single-page">
            <div className="wrapper">

                 <div className="section-limiter">
                    <div className="sectionHeader">
                        <div className="row">
                            <div className="col-8"><h6 onClick={handleGoBack} className="text-left"><i className="fa fa-arrow-left" aria-hidden="true"></i></h6></div>
                        </div>
                    </div>

                    <center>
                        <div style={{background: `url(${minister ? minister.photo : fillerPhoto})`}} className="contact-image"></div>
                        <div className="contact-name">{minister?.first_name} {minister?.last_name}</div>
                        <div className="contact-designation">{minister?.type}</div>
                    </center>

                    <div id="contact-action-widgets">
                        <div className="row">
                            <div className="offset-3 col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <center>
                                            <a href={`sms:${minister?.mobile}`}>
                                                <div className="action-icon">
                                                    <i className="fa fa-commenting-o" />
                                                </div>
                                            </a>
                                        </center>
                                    </div>
                                    <div className="col-6">
                                        <center>
                                            <a href={`tel:${minister?.mobile}`}>
                                                <div className="action-icon">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </a>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="contact-profile-information">

                    <div className="profile-information-instance">
                        <div className="profile-info-label">Designation</div>
                        <div className="profile-info-value">{minister?.type}</div>
                    </div>

                    <div className="profile-information-instance">
                        <div className="profile-info-label">Locale</div>
                        <div className="profile-info-value">Malagasang Imus</div>
                    </div>

                    <div className="profile-information-instance">
                        <div className="profile-info-label">Designation</div>
                        <div className="profile-info-value">0999-123-4567</div>
                    </div>

                    <div className="profile-information-instance">
                        <div className="profile-info-label">Email Address</div>
                        <div className="profile-info-value">{minister?.email}</div>
                    </div>

                    {/*<div id="profile-group-wrapper">
                        <h6>Groups</h6>
                        {category_toggle}
                    </div>*/}
                </div>
            </div>
        </div>
    );
}

export default ContactProfile;