import React, { useState, Navigate } from 'react';
import logo from '../assets/logo.png';
import pmcclogo from "../assets/pmcc-icon.png";

import fb from '../assets/fb.png';
import google from '../assets/google.png';

import { api } from '../api.js';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    let postData = { email, password };
    api("authentication_login", null, postData, "login")
      .then((result) => {

        console.log("login", result);

        localStorage.setItem("auth_result", JSON.stringify(result));
        localStorage.setItem("authenticated", true);
        localStorage.setItem("auth_name", result.name);
        localStorage.setItem("auth_customId", result.customId);
        localStorage.setItem("auth_userLevel", result.userLevel);
        localStorage.setItem("auth_localId", result.localId);
        localStorage.setItem("auth_ AccessId", result.AccessId);
        window.location.replace('/dashboard');

      }).catch((error) => {

          console.log(error);
          console.log("Something went wrong. " + JSON.stringify(error));

      });
  };

  return (
    <div id="login-page">
      <section className="full-page">
        <div className="vertical-align">
          <div id="loginForm" className="card card-primary">
            <div className="card-header text-center">
              
              <img id="pmcc-logo" src={pmcclogo} />
              <h2 id="loginHeader">
                Welcome back<br />
                <small>CEM Bishop Jonathan Ferriol</small>
              </h2>
            </div>
            <div className="card-body">

              {/*<div id="socialLoginWrapper">
                <div className="row">
                  <div className="col-6">
                    <div className="social-login google"><img src={google} className="social-media-login" /> Google</div>
                  </div>
                  <div className="col-6">
                    <div className="social-login facebook"><img src={fb} className="social-media-login" /> Facebook</div>
                  </div>
                </div>
              </div>*/}

              <form onSubmit={handleLogin}>
                <div className="row">
                  <div className="col-md-12 login-rows">
                    <div className="input-group mb-2 mr-sm-2">
                      <input className="form-control"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 login-rows">
                    <div className="input-group mb-2 mr-sm-2">
                      <input
                        placeholder="Password"
                        className="form-control"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>  
                  </div>
                  <div className="col-md-12">
                    <a id="forgotPasswordLink" className="pull-right" href="#">Forgot Password</a>
                    <div className="text-center">
                      <button id="login-button" className="btn btn-success brn-lg login" type="submit">Login</button>
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/*<a className="url-link" href="#" id="registrationLink">Don't have an account? Join us</a>*/}
        </div>
      </section>
    </div>
  );
};

export default LoginForm;