import React, { useState, useEffect } from 'react';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
    const [pages, setPages] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(totalPages);
        generatePageNumbers(currentPage, totalPages);
    }, [totalItems, currentPage]);

    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageLinks = 6;
        const segmentStart = Math.floor((currentPage - 1) / maxPageLinks) * maxPageLinks + 1;
        const segmentEnd = Math.min(segmentStart + maxPageLinks - 1, totalPages);

        const pageNumbers = [];
        for (let i = segmentStart; i <= segmentEnd; i++) {
            pageNumbers.push(i);
        }
        setPages(pageNumbers);
    };

    return (
        <ul className="pagination">
            <li 
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)}
                className="page-item">
                    <a class="page-link" href="#"><i className="fa fa-caret-left" /></a>
            </li>


            {pages.map((page) => (
                <li 
                    key={page}
                    className={page === currentPage ? 'active page-item' : 'page-item'}
                    onClick={() => onPageChange(page)}
                >
                        <a className='page-link' href='#'>{page}</a>
                </li>
            ))}
            
            <li 
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(currentPage + 1)}
                className="page-item">
                    <a class="page-link" href="#"><i className="fa fa-caret-right" /></a>
            </li>
        </ul>
    );
};

export default Pagination;