import {Component} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api } from '../api.js';
import { config } from '../config.js';

import React from "react";
import Slider from "react-slick";

import pmccIcon from "../assets/pmcc-icon.png";

import fillerPhoto from "../assets/filler.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Pagination from '../Components/Pagination';
import CommonLoader from "../CommonComponents/CommonLoader";

const token = localStorage.getItem("auth_token");
const headerReq = { token };
const settings = {
    slidesToShow: 3.5,
    rows: 2,
    slidesPerRow: 1,

    infinite: false,        // Disable infinite scroll
    swipeToSlide: true,     // Allow scrolling to any position
    speed: 300,             // Adjust speed for smoother transitions
};

const group_categories = [
    "Bishop",
    "Council Members",
    "Head Minister",
    "Bethel",
    "Mizpah",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer",
    "Achaia",
    "Smyrna",
    "Bethlehem",
    "Ebenezer"
];

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();

    return <Component {...props} navigate={navigate} location={location} />;
  };
}

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchString        : "",
            searchLocaleIds     : [],
            searchGroups        : [],

            isLoading           : true,
            initial_ministers   : [],
            ministers           : [],
            localeChurches      : [],
            area                : [],

            totalItems          : null,
            itemsPerPage        : null,
            currentPage         : null
        };

        this.searchString = React.createRef();
        this.searchForm = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    async componentDidMount(){
        const { location } = this.props;
        const hasQueryParams = new URLSearchParams(location.search).toString().length > 0;
        console.log("search params: ", location.search);
        
        await this.fetchMinisters(1);
        await this.fetchLocaleChurches();
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    fetchMinisters = async (pageNumber, search_locale_ids) => {
        console.log("fetch minister");
        let { ministers, totalItems, itemsPerPage, currentPage, searchString, searchLocaleIds, searchGroups } = this.state;
        let that = this;
        that.setState({isLoading : true});

        headerReq.page = pageNumber;
        headerReq.search_string = searchString;
        headerReq.groups = searchGroups;
        
        if(search_locale_ids) {
            headerReq.locale_ids = search_locale_ids;
        } else {
            headerReq.locale_ids = searchLocaleIds;
        }
        
        if(searchString == "") {
            headerReq.locale_ids = [];
            searchLocaleIds = [];
        }

        api("ministers_list", headerReq, null, "member")
        .then((result) => {

            if((searchString && searchString != "") || (search_locale_ids && search_locale_ids.length > 0) || (searchGroups && searchGroups.length > 0)) {
                console.log("url params");
                this.props.navigate("/dashboard?page=" + pageNumber+"&search_string="+searchString+"&locale_ids="+search_locale_ids+"&groups="+searchGroups);
            }

            if(search_locale_ids && search_locale_ids.length > 0) {
                console.log(result);
                this.setState({ministers : result, totalItems : result.total, itemsPerPage : result.per_page, currentPage : result.current_page, searchLocaleIds : search_locale_ids, isLoading: false });
            } else {
                console.log(result);
                this.setState({ministers : result, totalItems : result.total, itemsPerPage : result.per_page, currentPage : result.current_page, searchLocaleIds : [], isLoading: false });
            }

        }).catch((error) => {

            console.log(error);
            console.log("Something went wrong. " + JSON.stringify(error));

        });
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    async addGroup(group){
        // Create a shallow copy of the searchGroups array from the state
        let searchGroups = this.state.searchGroups;

        // Find the index of the group in the searchGroups array
        const index = searchGroups.findIndex(g => g === group);

        if (index === -1) {
            // If the group doesn't exist in the array, add it
            searchGroups.push(group);
        } else {
            // If the group already exists, remove it
            searchGroups.splice(index, 1);
        }

        // Update the state with the new searchGroups array
        console.log(searchGroups);
        this.setState({ searchGroups });
        await this.fetchMinisters(1);
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    handleChange = (event) => {
        this.setState({searchString : event.target.value});
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    async handlePageChange(pageNumber){
        console.log(pageNumber);
        await this.fetchMinisters(pageNumber);
    };

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    async handleSubmit(submit) {
        let searchString = this.state.searchString;
        let searchLocaleIds = [];
        console.log(searchString);

        const localeInstance = this.state.localeChurches.filter(function (locale) {
            return locale.localeName.toLowerCase().includes(searchString.toLowerCase());
        });

        const areaInstance = this.state.localeChurches.filter(function (locale) {
            return locale.areaName.toLowerCase().includes(searchString.toLowerCase());
        });

        console.log("area", areaInstance);
        localeInstance.concat(areaInstance);

        if(localeInstance.length > 0) {
            localeInstance.forEach((locale) => {
                searchLocaleIds.push(locale.localeId);
            });
        }

        if(searchString == "") {
            await this.fetchMinisters(1, []);
        } else {
            await this.fetchMinisters(1, searchLocaleIds);
        }

        submit.preventDefault();
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    generateLocaleAndArea(result) {
        let locale_churches = [];
        let area = [];

        result.forEach((result_instance) => {
            let locale_index = result_instance.localeId;
            locale_churches[locale_index] = result_instance;
            area[locale_index] = result_instance.areaName;
        });

        let uniqueArea = area.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        console.log(uniqueArea);
        this.setState({localeChurches : locale_churches, area : uniqueArea});
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    fetchLocaleChurches = async (pageId) => {
        let that = this;
        that.setState({isLoading : true});

        let gc_token = config.GC_SERVICE_URL.token;
        let gc_headerReq = { bearer_token : gc_token };
        let locale_churches = [];
        let area = [];
        
        let localStorage_localChurches = localStorage.getItem("locale_churches");

        if(!localStorage_localChurches) {
            api("all_locale_list", gc_headerReq, null, "gc")
            .then((result) => {

                localStorage.setItem("locale_churches", JSON.stringify(result));
                this.generateLocaleAndArea(result);

            }).catch((error) => {

                console.log(error);
                console.log("Something went wrong. " + JSON.stringify(error));

            });
        } else {
            this.generateLocaleAndArea(JSON.parse(localStorage_localChurches));
        }
    }

    /* -------------------------------------- FUNCTION SEPARATOR -------------------------------------- */

    render() {
        let { ministers, localeChurches, totalItems, itemsPerPage, currentPage, onPageChange, searchGroups } = this.state;
        let slider_entries = [];
        let minister_entries = [];

        group_categories.forEach((group, group_index) => {
            let groupClass = "groupButtonInstance";
            if(searchGroups.includes(group)) {
                groupClass += " active-group";
            }

            slider_entries.push(
                <div key={group_index} onClick={() => this.addGroup(group)} className={groupClass}>
                    <div className="buttonWrapper">
                        <img src={pmccIcon} />
                    </div>
                    <div className="group-label">{group}</div>
                </div>
            );
        });

        let displayLimit = 20;
        let inc = 0;

        if(ministers.hasOwnProperty('data')) {
            ministers.data.forEach((minister, minister_index) => {
                let minister_area = null;
                let contact_number = null;
                let locale_name = null;
                let profileLink = "/contact-profile/"+minister.id; 

                if(localeChurches[minister.current_locale]) {
                    minister_area = localeChurches[minister.current_locale].areaName;
                    contact_number = localeChurches[minister.current_locale].mobileNumber; 
                    locale_name =  localeChurches[minister.current_locale].localeName; 
                }

                let contact_link = "tel: "+minister.mobile;

                let photoDisplay = fillerPhoto;
                if(minister.photo) {
                    photoDisplay = minister.photo;
                }

                if(inc < displayLimit) {
                    minister_entries.push(
                        <a key={minister_index} href={profileLink}>
                            <div className="contact-instance">
                                <div className="contact-infromation-wrapper">
                                    <div style={{background: `url(${photoDisplay})`}} className="contact-image"></div>
                                    <div className="contact-information">
                                        <div className="contact-designation">{minister.type}</div>
                                        <div className="contact-name">{minister.first_name} {minister.last_name}</div>
                                        <div className="contact-area">
                                            {minister_area} Area<br />
                                            <small><i>{ locale_name }</i></small>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-appointment-wrapper">
                                    <a href={contact_link} className="appointment-link btn btn-primary">Call</a>
                                </div>
                            </div>
                        </a>
                    )
                }
                inc++;
            });
        }

        if(!this.state.isLoading) {
            return (
                <div>
                    <header className="authenticated">
                      <h6>
                        <small>Welcome back!</small><br />
                        CEM Bishop Jonathan Ferriol
                      </h6>
                    </header>
                    <div className="wrapper">
                        <div className="section-limiter">
                            <form ref={this.goodmanIdForm} id="minister-search" method="POST" onSubmit={this.handleSubmit}>
                                <div id="searchWrapper">
                                    <div className="form-group">
                                        <input onChange={(event) => this.handleChange(event)} className="form-control" type="text" placeholder="Search" />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="section-limiter">
                            <div className="sectionHeader">
                                <div className="row">
                                    <div className="col-8"><h6 className="text-left">Group</h6></div>
                                    <div className="col-4"><a className="subHeaderLabel pull-right">See All</a></div>
                                </div>
                            </div>
                        </div>

                        <div id="dashboard-slider-container" className="slider-container">
                          <Slider {...settings}>
                            {slider_entries}
                          </Slider>
                        </div>

                         <div className="section-limiter">
                            <div className="sectionHeader">
                                <div className="row">
                                    <div className="col-8"><h6 className="text-left">Contacts</h6></div>
                                    <div className="col-4"><a className="subHeaderLabel pull-right">See All</a></div>
                                </div>
                            </div>

                            <div id="contactWrapper">
                                {minister_entries}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                    <Pagination
                                        totalItems={totalItems}
                                        itemsPerPage={itemsPerPage}
                                        currentPage={currentPage}
                                        onPageChange={this.handlePageChange}
                                    />
                                </center>
                            </div>
                        </div>

                    </div>
                </div>
            )
        } else {
          return (
            <CommonLoader />
          );
        }
    }
}

export default withNavigate(Dashboard);